<template>
  <div class="user-management-container" data-test-id="userManagementContainer">
    <NavigationDrawer
      title="User Management"
      title-icon="mdi-shield-account"
      data-test-id="userManagementNavigationDrawer"
      :value="selectedTab"
      :items="tabs"
      :width="navigationDrawerWidth"
      @minified="(minified) => (minify = minified)"
      @input="openTab"
    />
    <router-view class="user-management-content" />
  </div>
</template>

<script>
import mainOverviewMixin from "mixins/main-overview-mixin";
import NavigationDrawer from "components/common/templates/NavigationDrawer";
export default {
  mixins: [mainOverviewMixin],
  components: {
    NavigationDrawer,
  },
  provide() {
    return {
      getPageWidth: () => {
        return this.getPageWidth() - this.navigationDrawerWidth;
      },
    };
  },

  data() {
    return {
      minify: false,
    };
  },

  methods: {
    async openTab(tab) {
      const route = tab.route;
      if (this.$route.matched.some(({ name }) => name === route.name)) {
        return;
      }
      await this.$router.push(route);
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    navigationDrawerWidth() {
      return this.minify ? 80 : 350;
    },

    selectedTab() {
      this.$route.name;
      const tab = this.tabs.find((tab) => {
        const routeName = tab.route?.name;
        return this.$route.matched.some(({ name }) => name === routeName);
      });
      return tab;
    },

    tabs() {
      return [
        {
          id: "users",
          name: "Users",
          icon: "mdi-account-cog",
          route: {
            name: "users",
            params: {
              ...this.$route.params,
              domain: this.selectedDomain,
            },
          },
        },

        {
          id: "groups",
          icon: "mdi-account-group",
          name: "Groups",
          route: {
            name: "groups",
            params: {
              ...this.$route.params,
              domain: this.selectedDomain,
            },
          },
        },
      ];
    },
  },
};
</script>

<style scoped>
.user-management-container {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;
}
</style>